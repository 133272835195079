<template>
  <div class="accordion">
    <div class="accordion__header">
      <slot name="heading" />
      <button class="accordion__trigger" @click="toggle">
        <i class="fal" :class="icon" />
      </button>
    </div>
    <transition
      name="expand"
      @enter="enter"
      @after-enter="afterEnter"
      @leave="leave"
    >
      <div v-show="show" class="accordion__body">
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Accordion',
  props: {
    closedByDefault: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: !this.closedByDefault
    }
  },
  computed: {
    icon() {
      return this.show ? 'fa-minus' : 'fa-plus'
    }
  },
  methods: {
    toggle: function() {
      this.show = !this.show
    },
    enter(element) {
      element.style.height = 'auto'

      const height = getComputedStyle(element).height

      element.style.height = 0

      getComputedStyle(element).height

      requestAnimationFrame(() => {
        element.style.height = height
      })
    },
    afterEnter(element) {
      element.style.height = 'auto'
    },
    leave(element) {
      const height = getComputedStyle(element).height

      element.style.height = height

      getComputedStyle(element).height

      requestAnimationFrame(() => {
        element.style.height = 0
      })
    }
  }
}
</script>

<style lang="scss">
.accordion__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accordion__trigger {
  background: none;
  outline: none;
  border: none;
  font-size: 20px;
  font-weight: 300;
}

.accordion__body {
  border-top: 1px solid $color-gray;
  padding-top: $spacing-m;
  margin-top: $spacing-m;
}

.expand-enter-active,
.expand-leave-active {
  transition: height 150ms ease-in-out;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  height: 0;
}
</style>
