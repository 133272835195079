<template>
  <div class="page">
    <div
      class="page-header-with-title"
      :style="{ backgroundImage: `url(${journey.headerImagePath})` }"
    >
      <h1>
        {{ journey.name }} <span>{{ journey.subtitle }}</span>
      </h1>
    </div>
    <Content v-show="!loading" class="level__content">
      <div class="swiper__container">
        <swiper ref="levelSwiper" :options="swiperOptions" :initial-slide="1">
          <swiper-slide v-for="level in levels" :key="level.id">
            <LevelCard
              :category="level.levelLabel"
              :title="level.name"
              :image-src="level.cardImagePath"
              :points="parseInt(level.points)"
              :completed="level.hasUserCompleted"
              :router-link="'levels/' + level.id"
              :locked="lockLevelCard(level.id, level.hasUserCompleted)"
            />
          </swiper-slide>
          <div slot="pagination" class="swiper-pagination"></div>
        </swiper>
      </div>
      <Card background-color="#F7F8F3" class="journey__info">
        <Accordion>
          <div slot="heading">
            <h3>Journey description</h3>
          </div>
          <div slot="content">
            <h4>Achievements on offer</h4>
            <div class="journey-stats">
              <div class="journey-stats__item">
                <Pill type="primary">{{ getPointsAvailable }}pts</Pill>
                available
              </div>
              <div class="journey-stats__item">
                <Pill type="tertiary">£{{ journey.savings }}+</Pill>
                savings
              </div>
              <div class="journey-stats__item">
                <Pill type="secondary">£{{ journey.taxRelief }}+</Pill>
                tax relief
              </div>
            </div>
            <p v-html="journey.description" />
          </div>
        </Accordion>
      </Card>
      <FlexContainer v-if="getNextLevelId" padding-vertical="normal">
        <ButtonRouterLink
          v-if="isOnLevelOne"
          type="success"
          :to="`levels/${getNextLevelId}`"
        >
          Begin journey
        </ButtonRouterLink>
        <ButtonRouterLink
          v-else
          type="secondary"
          :to="`levels/${getNextLevelId}`"
        >
          Continue journey
        </ButtonRouterLink>
      </FlexContainer>
      <FlexContainer v-else padding-vertical="normal">
        <ButtonRouterLink type="success" :to="`summary?confetti=true`">
          View Challenges
        </ButtonRouterLink>
      </FlexContainer>
    </Content>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
// import LevelItem from '@/components/journeys/JourneyItem.vue'
import LevelCard from '@/components/global/ui/cards/LevelCard.vue'
import Accordion from '@/components/global/ui/Accordion'

export default {
  components: {
    LevelCard,
    Accordion
  },
  data: function() {
    const self = this

    return {
      loading: null,
      journeyId: null,
      swiperOptions: {
        initialSlide: this.nextLevel,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          renderBullet(index, className) {
            return `<span class="${className} ${self.isLevelCompleted(
              self.levelsArray[index].id
            ) && 'swiper-pagination-bullet-passed'}"><span class="tick"
        ><i class="far fa-check"></i
      ></span><span class="level">Level ${index + 1}</span></span>`
          }
        }
        // Some Swiper option/callback...
      }
    }
  },
  computed: {
    ...mapGetters({
      levels: 'levels/getLevels',
      levelsArray: 'levels/getLevelsAsArray',
      journey: 'journey/getJourney',
      getPointsAvailable: 'journey/getPointsAvailable',
      getNextLevelIndex: 'levels/getNextLevelIndex',
      getNextLevelId: 'levels/getNextLevelId',
      isLevelCompleted: 'levels/isCompleted'
    }),
    swiper() {
      return this.$refs.levelSwiper?.$swiper
    },
    isOnLevelOne() {
      if (this.getNextLevelId === this.levelsArray[0]?.id) {
        return true
      }
      return false
    }
  },
  created() {
    this.loading = true
  },
  async mounted() {
    this.journeyId = this.$route.params.journeyId
    await this.$store.dispatch('levels/openDBChannel', {
      pathVariables: {
        journeyId: this.journeyId
      },
      clauses: {
        orderBy: ['order']
      }
    })
    await this.$store.dispatch('journey/openDBChannel', {
      pathVariables: {
        journeyId: this.journeyId
      }
    })
    // this.nextLevel =
    this.loading = false
    this.swiper.slideTo(this.getNextLevelIndex)
  },
  methods: {
    ...mapActions({}),
    routeToLevel(levelId) {
      this.$router.push('levels/' + levelId)
    },
    lockLevelCard(levelId, userCompleted) {
      const isLevelNotNextLevel = levelId !== this.getNextLevelId
      if (isLevelNotNextLevel && !userCompleted) {
        return true
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/sass/components/stats';
@import '@/assets/sass/components/page-header';
@import '@/assets/sass/components/swiper';

.page {
  @include respond-above(sm) {
    width: 100%;
    margin-top: 60px;
  }
}

.level__content {
  @include respond-above(sm) {
    padding: 2.4rem 15vw !important;
  }
}

.swiper__container {
  @include respond-above(sm) {
    width: 80%;
    margin: 60px auto 0 auto;
  }
}

.level__subheading {
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  margin-bottom: 0;
}

.level__heading {
  font-size: 24px;
  line-height: 26px;
  font-weight: 600;
}

.level__points {
  text-align: right;
}

.journey__info {
  h3 {
    font-size: 20px;
    font-family: Barlow, sans-serif;
    font-weight: 400;
    color: $color-gray--dark;
    margin-bottom: 0;
  }
  h4 {
    font-size: 18px;
    font-family: Barlow, sans-serif;
    font-weight: 500;
    color: $color-gray--dark;
    text-align: center;
  }
}

.journey-stats {
  @extend .stats;
  margin-top: 0;
  justify-content: center;

  &__item {
    @extend .stats__item;
    flex-direction: column;
    margin: $spacing-s 1rem $spacing-m;

    .pill {
      margin: 0 auto 0.4rem;
    }
  }
}
</style>
